import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import PageNotFound from "../404/page-not-found";
import { scrollToElement } from "../../../utility/utils";

const NotFoundPage = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <PageNotFound />
            <CTA />
        </Fragment>
    )
}

export default NotFoundPage;
