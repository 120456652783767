import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NotFoundPage from "../components/body/pages/not-found-page"

const NotFound = () => (
  <Layout>
    <SEO canonical={"https://kuda.com/404/"} noIndex={true} />
    <NotFoundPage />
  </Layout>
)

export default NotFound

