import React, { Fragment } from "react"
import NotFoundIllustration from "../../../../assets/img/page-not-found-error-illustration.inline.svg";
const PageNotFound = () => {
  return (
    <Fragment>
        <div className="kuda-section--100">
            <div className="kuda-section--inner">
                <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                    <div className="flex flex-column">
                        <h1 className="mx-auto kuda-section--heading mb-0 text-lg-biz text-xlbold color-primary bottom--spacing">
                        Page not found
                        </h1>
                        <p className="mx-auto text-semi-bold color-black">
                    Sorry, an unexpected error occured. Please try again.
                        </p>
                        <div className="kuda-illustration--center">
                            <NotFoundIllustration />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
  )
}
export default PageNotFound